import React from 'react'; 
import IndustriesPage from '../../components/IndustriesPage';

const data = {
    heroSection: {
      heading: ['High-Precision', 'Medical Equipment'], 
      imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-logo.png',
      content: [
        'Solutions to meet strict quality control and compliance requirements, while keeping cost of quality low.', 
      ]
    }, 
    problemsSection: {
        heading: 'Challenges in Precision Medical Device Manufacturing ', 
        minHeight: '17rem',
        content: [
            {
                title: ['Strict Quality', 'Requirements'], 
                text: 'Informal quality processes, rigid QMS and workforce fluctuations make it difficult to consistently deliver highest quality at low cost. inspection causes operator fatigue and errors', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/slow-icon.png', 
            },
            {
                title: ['Packaging', 'Regulations'], 
                text: 'Manual inspections to meet strict packaging regulations are slow, limit order fulfillment and prevent competitive pricing of your products parts require double and triple inspection checks', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/packaging-regulations-icon.png'
            },
            {
                title: ['Expensive', 'Recalls'], 
                text: 'Recalls of defective products result in significant revenue loss and erosion of trust, compelling you to invest heavily in manual quality control and redundant inspections.', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/high-costs-icon.png'
            }
        ]   
    }, 
    contentSection: [
      {
        type: 'light', 
        headerContent: {
          labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-icon.png', 
          labelImgDimensions: {
            width: '3.81rem', 
            height: '4.68rem'
          },
          heading: 'Automate defect detection', 
          description: 'Augment microscope based quality inspection with AI software, to significantly reduce cost of quality and increase QC throughput', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-content-01.png', 
          mobileImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-content-01-mobile.png', 
          imgDimensions: {
            // width: '45.75rem', 
            width: '48.12rem',
            height: '25.62rem'
          }
        }, 
        content: [
          {
            title: 'Problem', 
            text: [
              'An American manufacturer produces high-precision surgical equipment measuring just 0.5mm in diameter which requires quality inspection under a microscope.',
              'It is mission-critical not to ship a part with a bent tip or incomplete weld. To minimize error during QC, the company employs two inspectors for double inspection of each part.'
            ]
          }, 
          {
            title: 'Solution', 
            text: [
                'A commodity camera mounted on the microscope provides a live video stream of the part under inspection to customized AI software.', 
                'The software identifies defects in real time and resports GOOD/BAD inspection result on a screen. Technicians perform inspections on a monitor, guided by a software assistant.'
            ]
          }, 
          {
            title: 'Outcome', 
            text: [
                {
                    primaryText: '100% lower cost of quality', 
                    description: 'As only a single inspector checks each part with AI augmented inspection, instead of two previously.'
                }, 
                {
                    primaryText: '4x higher throughput',
                    description: 'As inspection time is brought down from 4 minutes per part (2 minutes per inspector) to only 1 minute per part.'
                }
            ]
          }
        ], 
        imgExamples: {
            title: 'Use across defect types:', 
            content: [
                {
                    text: 'Discolored weld', 
                    imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/discolored-weld-example.png'
                },
                {
                    text: 'Bent tip', 
                    imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/bent-tip-example.png'
                },
                {
                    text: 'Surface Scratches', 
                    imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/scratch-defect-example.png'
                },
            ]
        }
      },
      {
        type: 'primary', 
        headerContent: {
          labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-paper-search-icon.png', 
          labelImgDimensions: {
            width: '3.62rem', 
            height: '3.75rem'
          },
          heading: 'Label and seal inspection', 
          description: 'Use automation to ensure all parts are properly sealed and labeled before shipping, for faster and accurate compliance at lower costs', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-content-02.png', 
          mobileImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/medical-devices-content-02-mobile.png',
          imgDimensions: {
            width: '50rem', 
            height: '21.68rem'
          }
        }, 
        content: [
          {
            title: 'Problem', 
            text: [
              'An American manufacturer produces high-precision surgical equipment measuring just 0.5mm in a diameter which requires quality inspection under a microscope.', 
              'Every part of a shipment must be properly sealed in sterile packaging by a customer, at the manufacturers expense'
            ]
          }, 
          {
            title: 'Solution', 
            text: [
              "A commodity camera is installed at the packaging station and provides a live video stream of parts being sealed and labeled to a customized AI software.", 
              'The software identifies a broken seal or missing label in real time and raises an alert. Operators intervene to correct the issue, preventing the shipment of improperly packaged parts to customers'
            ]
          }, 
          {
            title: 'Outcome', 
            text: [
              {
                primaryText: '50% cost savings', 
                description: 'From timely detection of improper packaging resulting in lower rejection rate by customers'
              }, 
              {
                primaryText: '2x faster packaging', 
                description: 'By using software automation for package in place of slow manual checking'
              }
            ]
          }
        ]
      },
    ]
  }
  
  

export default function MedicalDevicesPage(){
    return <IndustriesPage data={data} />
}